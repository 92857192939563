import { SortOrder } from '@generated/graphql'

import * as _RoleHandler from './modules/roles'
import * as _permissions from './permissions'
import _roles from './roles/index'

export const roles = _roles
export const permissions: { [key: string]: Permissions } = _permissions
export const RoleHandler = _RoleHandler

export type Role = {
  name: string
  permissions: Array<any>
}

export type Permissions = Partial<{
  view: string
  create: string
  edit: string
  delete: string
}>

export const extractPermissionsFromMetadata = (app_metadata: any) => {
  const roles = (app_metadata?.roles ?? [])
    ?.map((role: any) => {
      return RoleHandler.getRole(role?.name)
    })
    .filter((e?: Role) => e)

  return [
    ...(app_metadata?.permissions ?? []).map(
      (permission: any) => permission.name,
    ),
    ...RoleHandler.extractPermissions(roles),
  ]
}

export const DEFAULT_LANGUAGE = {
  id: 'cka2r9yww001164rm5rp69iko',
  countryCode: 'US',
  languageCode: 'en',
  languageTag: 'en',
  name: 'English',
}

// TODO: need to be refactored CMS-696
export const NEWS_TAXONOMY_ID = 'recl3sJERtdtqPlbf'
export const NEWS_TAXONOMY_TITLE = 'News'
export const PILLAR_SECTIONS_PARENT_TITLE = 'Pillar sections'
export const TAXONOMY_GROUP_PARENT_TITLE = 'Taxonomy Group'

export const DRAFT_TOOLTIP_TEXT = {
  SINGULAR: 'Field that was changed and saved as a draft but not approved',
  PLURAL: 'Fields that were changed and saved as a draft but not approved',
}

export const TableSortFromGQLSortOrder = {
  [SortOrder.Desc]: 'descend',
  [SortOrder.Asc]: 'ascend',
}

export const DEFAULT_SELECTOR_SEARCH_PARAM_KEY = 'all'
export const DEFAULT_SELECTOR_STORE_PARAM_KEY = null
export const USER_SELECTED_NETWORK_KEY = 'userSelectedNetwork'
export const USER_SELECTED_VERTICAL_KEY = 'userSelectedVertical'
export const DYNAMIC_VARIABLE_KEY = 'ui_variable'

export const DEFAULT_TABLE_PAGE = 1
export const DEFAULT_TABLE_PAGE_SIZE = 30
export const DEFAULT_TABLE_PAGE_SIZE_OPTIONS = ['30', '50', '100', '250']

export const ALLOWED_IMAGE_FORMATS = ['webp', 'jpeg', 'jpg', 'svg', 'png']
export const ALLOWED_VIDEO_FORMATS = ['mp4', 'webm', 'ogg', 'avi', 'mov']

export const GLOBAL_CONTEXT_MARKER = 'GLOBAL'
