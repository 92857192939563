export const IS_NEW_PROPERTY_KEY = 'is-new-import-from-provider-portal'
export const PUBLISHED_KEY = 'published'
export const PUBLISHED_AT_KEY = 'published-at'
export const PARENT_KEY = 'parent'
export const NAME_KEY = 'name'
export const ABBREVIATED_NAME_KEY = 'abbreviated-name'
export const TITLE_KEY = 'title'
export const CONTENT_KEY = 'content'
export const PLACEHOLDER_KEY = 'placeholder'
export const CREATED_AT_KEY = 'createdAt'
export const SUSPENDED_KEY = 'suspended'
export const FEATURED_IMAGE_KEY = 'featured-image'
export const LOGOTYPE_KEY = 'logotype'
export const CALCULATED_RESTRICTED_COUNTRIES_KEY =
  'calculated-restricted-countries'
export const PUBLISHED_COUNTRIES_KEY = 'published-countries'
export const HIDE_COUNTRIES_KEY = 'hide-countries'
export const META_DESCRIPTION_KEY = 'meta-description'
export const HTML_TITLE_KEY = 'html-title'
export const SECTION_KEY = 'section'
export const TAGS_KEY = 'tags'
export const LABEL_KEY = 'label'
export const ICON_KEY = 'icon'
export const OPTIONS_KEY = 'options'
export const MENU_COUNT_KEY = 'count'
export const MENU_PRODUCT_TYPE_KEY = 'product-type'
export const MENU_PROVIDER_TAXONOMY_KEY = 'provider-taxonomy'
export const MENU_SOFTWARE_TAXONOMY_KEY = 'software-taxonomy'
export const MENU_GAME_TAXONOMY_KEY = 'game-taxonomy'
export const MENU_VERTICAL_KEY = 'vertical'
export const DATA_URL_KEY = 'data-url'
export const PATH_KEY = 'path'
export const PROFILE_PICTURE_KEY = 'profile-picture'
export const SELECTOR_KEY = 'selector'
export const FIELD_KEY = 'field'
export const TEMPLATE_DEFINITION_KEY = 'template-definition'
export const DESCRIPTION_KEY = 'description'
export const SHORT_TITLE_KEY = 'short-title'
export const SHOULD_BE_APPROVED_KEY = 'shouldBeApproved'
export const SHOULD_CREATE_INLINE_IMAGES_KEY = 'shouldCreateInlineImages'
export const NETWORKS_KEY = 'networks'
export const VERTICALS_KEY = 'verticals'
export const IMAGE_KEY = 'image'
export const PILLAR_CONTENT_KEY = 'pillar-content'
export const AFFILIATE_LINK_KEY = 'affiliate-link'
export const FOCUS_GEOS_KEY = 'focus-geos'

export const BLACKLISTED_FIELDS = ['slug']
export const DEFAULT_LOCALIZE_FIELDS = ['content', 'faq', 'intro']
export const MENU_ITEM_LABEL_FIELD_KEY = 'label'
export const ROLE_KEY = 'role'
